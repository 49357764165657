import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Gleap from 'gleap';

// Please make sure to call this method only once!
Gleap.initialize("heBYFzkbJDAyKgds8np3Z4wAfayyHsc9");

Sentry.init({
  dsn: "https://abd29fc8f022b34277ec8bbc5543edd7@o4508474368131072.ingest.us.sentry.io/4508474371342336",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracePropagationTargets: ["localhost", /^https:\/\/www\.internguys\.com\/interview/],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
