import React, { useState } from "react";
import CheckCircle from "../../images/CheckCircle.png"; 
import "./style.css";

const InterviewCompletedScreen = ({ candidateToInterviewId, companyName, position }) => {
    const [feedbackText, setFeedbackText] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmitFeedback = async () => {
        if (!feedbackText.trim()) {
            alert("Please provide feedback before submitting.");
            return;
        }
    
        setIsSubmitting(true);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_POST_INTERVIEW_FEEDBACK}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    feedback: feedbackText.trim(),
                    candidateToInterviewId,
                    companyName,
                    position,
                }),
            });
    
            if (response.ok) {
                alert("Thank you for your feedback!");
                setFeedbackText(""); 
            } else {
                const errorData = await response.json();
                console.error("Error submitting feedback:", errorData);
                alert("Failed to submit feedback. Please try again later.");
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            alert("An error occurred while submitting your feedback. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="completionScreen">
          <div className="completion-content">
            <img src={CheckCircle} alt="Completion Check" className="completion-icon" />
            <h2>Your Interview with Nora is complete!</h2>
            <p>You may now exit out of this browser.</p>
    
            <div className="interviewCompleted-feedback-section">
                <p className="interviewCompleted-feedback-question">How was your experience? (optional)</p>
                <textarea
                    className="interviewCompleted-feedback-textarea"
                    placeholder="Is there anything we can do to improve your interview experience?"
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                />
                <button
                    className="interviewCompleted-submit-feedback-button"
                    onClick={handleSubmitFeedback}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Submitting..." : "Submit"}
                </button>
            </div>
          </div>
        </div>
    );
};

export default InterviewCompletedScreen;